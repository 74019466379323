@font-face {
  font-family: 'TT Commons Pro';
  src: url('./fonts/TT Commons Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
margin: 0;
font-family: 'TT Commons Pro', sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
